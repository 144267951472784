<template>
  <div class="bigDiv">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/08/172042137852581507.png" alt="" class="img">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/08/172042141477153119.gif" alt="" class="fixd" @click="throttleSubmit">
  </div>
</template>

<script>
import { getWxCsad } from "../../api/360AD.js";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      ruleForm: {

        land_link: "", //落地页链接
      },
    }
  },
  created () {
    this.ruleForm.land_link = window.location.href
  },
  methods: {
    throttleSubmit () {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick()
    },
    async wxClick () {
      const { data } = await getWxCsad(this.ruleForm);
      window.location.href = data.data;
    },
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  width: 100%;
  margin: 0 auto;
  .img {
    width: 100%;
    object-fit: contain;
    margin-bottom: 80px;
  }
  .fixd {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    overflow: hidden;
    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .fixd {
      width: 750px;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
    .btoText {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #ccc;
      margin: 10px auto;
    }
    .bto {
      margin-bottom: 150px;
    }
  }
}
</style>